<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Новый файл из 1С</span>
          <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <v-container>
        
        <v-row>
          <v-col>
            <ul class="steps">
              <li @click="currentStep = 1" :class="{ 'selected' : currentStep === 1}"><span>1</span>Загрузка файла</li>
              <li @click="currentStep = 2" :class="{ 'selected' : currentStep === 2}"><span>2</span>Обработка файла</li>
              <li @click="currentStep = 3" :class="{ 'selected' : currentStep === 3}"><span>3</span>Сохранение и отправка</li>
            </ul>
          </v-col>
        </v-row>

        <v-container v-if="currentStep === 1">
          <v-row>
              <v-col>
                <div class="file-area" @drop="drop">
                  <input style="display: none" type="file" name="fields[assetsFieldHandle][]" id="assetsFieldHandle" @change="onChange" ref="file" />
                
                  <label for="assetsFieldHandle">
                    <v-btn :disabled="true">Загрузите файл</v-btn>
                    <div>Или перетащите его в эту область</div>
                  </label>
                  <ul class="files-list" v-if="this.filelist.length" v-cloak>
                    <li class="text-sm p-1" v-for="file in filelist" :key="file.id">
                      {{ file.name }}<button class="ml-2" type="button" @click="remove(filelist.indexOf(file))" title="Удалить файл">Удалить</button>
                    </li>
                  </ul>
                </div>
              </v-col>
          </v-row>
        </v-container>



        <v-container v-if="currentStep === 2">
          <v-row>
            <v-col class="file-info">
              Загруженный файл
              <span class="badge">Выгрузка_1С_01122020.xlsx
                <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.66634 1.27301L8.72634 0.333008L4.99967 4.05967L1.27301 0.333008L0.333008 1.27301L4.05967 4.99967L0.333008 8.72634L1.27301 9.66634L4.99967 5.93967L8.72634 9.66634L9.66634 8.72634L5.93967 4.99967L9.66634 1.27301Z" fill="#3577BE"/>
                </svg>
              </span>
              <span>Кол-во записей <span class="blue">4</span></span>
              <span>Записей с ошибками <span class="orange">3</span></span>

              <v-dialog
                        v-model="dialog1"
                        max-width="600px"
                        persistent
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on">
                          <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5 10.14V0H3V10.14C1.28 10.59 0 12.14 0 14C0 15.86 1.28 17.41 3 17.86V20H5V17.86C6.72 17.41 8 15.86 8 14C8 12.14 6.72 10.59 5 10.14ZM4 12C5.1 12 6 12.9 6 14C6 15.1 5.1 16 4 16C2.9 16 2 15.1 2 14C2 12.9 2.9 12 4 12ZM15 0H13V2.14C11.28 2.59 10 4.14 10 6C10 7.86 11.28 9.41 13 9.86V20H15V9.86C16.72 9.41 18 7.86 18 6C18 4.14 16.72 2.59 15 2.14V0ZM14 4C15.1 4 16 4.9 16 6C16 7.1 15.1 8 14 8C12.9 8 12 7.1 12 6C12 4.9 12.9 4 14 4Z" fill="#3577BE"/>
                          </svg>
                        </v-btn>     					                     
                      </template>
                      <ColumnsDialog @close="dialog1=false"/>
              </v-dialog>

                       
            </v-col>
          </v-row>
          <v-row>
            <v-col>
                <table class="table1c">
                    <thead>
                      <tr>
                        <th>период</th>
                        <th>документ</th>
                        <th>аналитика дт</th>
                        <th>аналитика кт</th>
                        <th>дебет счет</th>
                        <th>сумма</th>
                        <th>кредит счет</th>
                        <th>сумма</th>
                        <th>организация</th>
                        <th>№ договора</th>
                        <th>проект</th>
                        <th>ответственный</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>						
                        <td>22.11.2020</td>
                        <td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
                        <td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
                        <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
                        <td>60.02</td>
                        <td>17575</td>
                        <td class="errorValue">51</td>
                        <td>17575</td>
                        <td>АНО «НИСИПП»</td>
                        <td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
                        <td><a href="#">Царь-монитор_2020</a></td>
                        <td>Шевернев Ю.А.</td>
                        <td>
                        <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn									
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                              <v-list-item-title>Редактировать</v-list-item-title>									
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-title>Удалить</v-list-item-title>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>						
                        <td>22.11.2020</td>
                        <td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
                        <td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
                        <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
                        <td>60.02</td>
                        <td>17575</td>
                        <td class="errorValue">51</td>
                        <td>17575</td>
                        <td>АНО «НИСИПП»</td>
                        <td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
                        <td><a href="#">Царь-монитор_2020</a></td>
                        <td>Шевернев Ю.А.</td>
                        <td>
                        <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn									
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                              <v-list-item-title>Редактировать</v-list-item-title>									
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-title>Удалить</v-list-item-title>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>						
                        <td>22.11.2020</td>
                        <td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
                        <td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
                        <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
                        <td>60.02</td>
                        <td>17575</td>
                        <td class="errorValue">51</td>
                        <td>17575</td>
                        <td>АНО «НИСИПП»</td>
                        <td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
                        <td><a href="#">Царь-монитор_2020</a></td>
                        <td>Шевернев Ю.А.</td>
                        <td>
                        <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn									
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                              <v-list-item-title>Редактировать</v-list-item-title>									
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-title>Удалить</v-list-item-title>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>						
                        <td>22.11.2020</td>
                        <td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
                        <td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
                        <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
                        <td>60.02</td>
                        <td>17575</td>
                        <td class="errorValue">51</td>
                        <td>17575</td>
                        <td>АНО «НИСИПП»</td>
                        <td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
                        <td><a href="#">Царь-монитор_2020</a></td>
                        <td>Шевернев Ю.А.</td>
                        <td>
                        <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn									
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                              <v-list-item-title>Редактировать</v-list-item-title>									
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-title>Удалить</v-list-item-title>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>						
                        <td>22.11.2020</td>
                        <td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
                        <td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
                        <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
                        <td>60.02</td>
                        <td>17575</td>
                        <td>51</td>
                        <td>17575</td>
                        <td>АНО «НИСИПП»</td>
                        <td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
                        <td><a href="#">Царь-монитор_2020</a></td>
                        <td>Шевернев Ю.А.</td>
                        <td>
                        <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn									
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                              <v-list-item-title>Редактировать</v-list-item-title>									
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-title>Удалить</v-list-item-title>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-if="currentStep === 3">
        
                    <v-row>
            <v-col>
                <table class="table1c">
                    <thead>
                      <tr>
                        <th>период</th>
                        <th>документ</th>
                        <th>аналитика дт</th>
                        <th>аналитика кт</th>
                        <th>дебет счет</th>
                        <th>сумма</th>
                        <th>кредит счет</th>
                        <th>сумма</th>
                        <th>организация</th>
                        <th>№ договора</th>
                        <th>проект</th>
                        <th>ответственный</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>						
                        <td>22.11.2020</td>
                        <td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
                        <td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
                        <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
                        <td>60.02</td>
                        <td>17575</td>
                        <td>51</td>
                        <td>17575</td>
                        <td>АНО «НИСИПП»</td>
                        <td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
                        <td><a href="#">Царь-монитор_2020</a></td>
                        <td>Шевернев Ю.А.</td>
                        <td>
                        <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn									
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                              
                                <v-dialog
                                      v-model="dialog2"
                                      max-width="600px"
                                      persistent
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-list-item-title v-bind="attrs" v-on="on">Редактировать</v-list-item-title>	                                          					                     
                                    </template>
                                    <NewEntryDialog @close="dialog2=false"/>
                            </v-dialog>                                								
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-title>Удалить</v-list-item-title>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>						
                        <td>22.11.2020</td>
                        <td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
                        <td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
                        <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
                        <td>60.02</td>
                        <td>17575</td>
                        <td>51</td>
                        <td>17575</td>
                        <td>АНО «НИСИПП»</td>
                        <td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
                        <td><a href="#">Царь-монитор_2020</a></td>
                        <td>Шевернев Ю.А.</td>
                        <td>
                        <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn									
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                              <v-list-item-title>Редактировать</v-list-item-title>									
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-title>Удалить</v-list-item-title>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>						
                        <td>22.11.2020</td>
                        <td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
                        <td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
                        <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
                        <td>60.02</td>
                        <td>17575</td>
                        <td>51</td>
                        <td>17575</td>
                        <td>АНО «НИСИПП»</td>
                        <td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
                        <td><a href="#">Царь-монитор_2020</a></td>
                        <td>Шевернев Ю.А.</td>
                        <td>
                        <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn									
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                              <v-list-item-title>Редактировать</v-list-item-title>									
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-title>Удалить</v-list-item-title>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>						
                        <td>22.11.2020</td>
                        <td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
                        <td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
                        <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
                        <td>60.02</td>
                        <td>17575</td>
                        <td>51</td>
                        <td>17575</td>
                        <td>АНО «НИСИПП»</td>
                        <td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
                        <td><a href="#">Царь-монитор_2020</a></td>
                        <td>Шевернев Ю.А.</td>
                        <td>
                        <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn									
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                              <v-list-item-title>Редактировать</v-list-item-title>									
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-title>Удалить</v-list-item-title>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                      <tr>						
                        <td>22.11.2020</td>
                        <td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
                        <td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
                        <td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
                        <td>60.02</td>
                        <td>17575</td>
                        <td>51</td>
                        <td>17575</td>
                        <td>АНО «НИСИПП»</td>
                        <td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
                        <td><a href="#">Царь-монитор_2020</a></td>
                        <td>Шевернев Ю.А.</td>
                        <td>
                        <v-menu
                            bottom
                            left
                          >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn									
                              icon
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item>
                              <v-list-item-title>Редактировать</v-list-item-title>									
                            </v-list-item>
                            <v-divider></v-divider>
                            <v-list-item>
                              <v-list-item-title>Удалить</v-list-item-title>
                            </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </tbody>
                </table>
            </v-col>
          </v-row>

        </v-container>

      </v-container>  
    </v-card-text>   


  </v-card>
</template>

<script>

import ColumnsDialog from './ColumnsDialog';
import NewEntryDialog from './NewEntryDialog';




  export default {
    name: 'Add1сtDialog',
    delimiters: ['${', '}'],
    data() {
    return {
      currentStep: 1,
      filelist: []
    };
  },
  methods: {
    onChange() {
      this.filelist = [...this.$refs.file.files];
      console.log(this.filelist)
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },

    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange();    
    }
  },
  components: { ColumnsDialog, NewEntryDialog }

  }
</script>

<style lang="scss" scoped>
.steps {
  list-style: none;
  display: flex;
  margin: 20px 0 34px 0;
  padding: 0;  
  li {
    margin-right: 20px;
    cursor: pointer;
    span {
      border: 1px solid #77809A;
      border-radius: 50%;
      display: inline-flex;
      flex-shrink: 0;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
      color: #77809A;
      margin-right: 10px;      
    }
    &.selected, &:hover {
      color: #3577BE;
      span {
        color: #3577BE;
        border-color: #3577BE;
      }
    }
  }
}

.file-area {
  width: 100%;
  min-height: 250px;
  background: #E3EBFC;
  border-radius: 10px;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .v-btn.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background: #3577BE !important;
    cursor: pointer !important;
    margin-bottom: 20px;
  }
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
  }
  .files-list {    
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 10px;
  }
}

 .file-info {
    font-size: 13px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .badge {
      height: 30px;
      background: #E3EBFC;
      border-radius: 30px;
      display: inline-flex;
      align-items: center;
      padding: 0 10px;
      margin: 0 20px 0 10px;
      svg {
        margin-left: 7px;
      }
    }
    span {
      margin-right: 10px;      
      &.blue {
        color: #3577BE;
        background: none !important;
      }
      &.orange {
        color: #FF6D00;
        background: none !important;
      }
    }
    button {
      margin-left: auto;
      width: 50px !important;
      min-width: 50px !important;
      height: 50px !important;
      background: #E3EBFC !important;
      padding: 0 !important;
      border-radius: 50% !important;
    }
  }

</style>
