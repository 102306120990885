<template>
	<v-container>

		<h1>
			<span>Реестр проводок, загруженных из 1С</span>
			<div>
                      <v-dialog
                        v-model="dialog"
                        max-width="1280px"
                        persistent
                    >
                      <template v-slot:activator="{ on, attrs }">
						<v-btn v-bind="attrs" v-on="on" class="add-link">Создать проводку</v-btn>                        
                      </template>
                      <Add1сDialog @close="dialog=false"/>
                    </v-dialog>


				
			</div>

		</h1>

		<Register :config="regConfig"></Register>
    

		<section>
			
			<table>
				<thead>
					<tr>
						<th>дата загрузки</th>
						<th>Наименование файла</th>
						<th>кол-во записей</th>
						<th>статус</th>
						<th>автор</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>10.01.2020</td>
						<td><a href="#">Выгрузка_1С_01012020.xlsx</a></td>
						<td>102</td>
						<td>Обработано</td>
						<td><a href="#">Старожилец Л.М.</a></td>
					</tr>
					<tr>
						<td>10.01.2020</td>
						<td><a href="#">Выгрузка_1С_01012020.xlsx</a></td>
						<td>102</td>
						<td>Обработано</td>
						<td><a href="#">Старожилец Л.М.</a></td>
					</tr>
					<tr>
						<td>10.01.2020</td>
						<td><a href="#">Выгрузка_1С_01012020.xlsx</a></td>
						<td>102</td>
						<td>Обработано</td>
						<td><a href="#">Старожилец Л.М.</a></td>
					</tr>
					<tr>
						<td>10.01.2020</td>
						<td><a href="#">Выгрузка_1С_01012020.xlsx</a></td>
						<td>102</td>
						<td>Обработано</td>
						<td><a href="#">Старожилец Л.М.</a></td>
					</tr>
					<tr>
						<td>10.01.2020</td>
						<td><a href="#">Выгрузка_1С_01012020.xlsx</a></td>
						<td>102</td>
						<td>Обработано</td>
						<td><a href="#">Старожилец Л.М.</a></td>
					</tr>
					<tr>
						<td>10.01.2020</td>
						<td><a href="#">Выгрузка_1С_01012020.xlsx</a></td>
						<td>102</td>
						<td>Обработано</td>
						<td><a href="#">Старожилец Л.М.</a></td>
					</tr>
				</tbody>
			</table>

			<br/>
			<br/>
			<br/>


			<table class="table1c">
				<thead>
					<tr>
						<th>файл</th>
						<th>период</th>
						<th>документ</th>
						<th>аналитика дт</th>
						<th>аналитика кт</th>
						<th>дебет сумма</th>
						<th>организация</th>
						<th>№ договора</th>
						<th>проект</th>
						<th>ответственный</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr>						
						<td><a href="#">Выгрузка_1С_01012020.xlsx</a></td>
						<td>22.11.2020</td>
						<td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
						<td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
						<td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
						<td>17 575</td>
						<td>АНО «НИСИПП»</td>
						<td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
						<td><a href="#">Царь-монитор_2020</a></td>
						<td>Шевернев Ю.А.</td>
						<td>
						<v-menu
								bottom
								left
							>
								<template v-slot:activator="{ on, attrs }">
								<v-btn									
									icon
									v-bind="attrs"
									v-on="on"
								>
									<v-icon color="#77809A">mdi-dots-vertical</v-icon>
								</v-btn>
								</template>

								<v-list>
								<v-list-item>
									<v-list-item-title>Редактировать</v-list-item-title>									
								</v-list-item>
								<v-divider></v-divider>
								<v-list-item>
									<v-list-item-title>Удалить</v-list-item-title>
								</v-list-item>
								</v-list>
							</v-menu>
						</td>
					</tr>
					<tr>						
						<td><a href="#">Выгрузка_1С_01012020.xlsx</a></td>
						<td>22.11.2020</td>
						<td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
						<td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
						<td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
						<td>17 575</td>
						<td>АНО «НИСИПП»</td>
						<td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
						<td><a href="#">Царь-монитор_2020</a></td>
						<td>Шевернев Ю.А.</td>
						<td>
						<v-menu
								bottom
								left
							>
								<template v-slot:activator="{ on, attrs }">
								<v-btn									
									icon
									v-bind="attrs"
									v-on="on"
								>
									<v-icon color="#77809A">mdi-dots-vertical</v-icon>
								</v-btn>
								</template>

								<v-list>
								<v-list-item>
									<v-list-item-title>Редактировать</v-list-item-title>									
								</v-list-item>
								<v-divider></v-divider>
								<v-list-item>
									<v-list-item-title>Удалить</v-list-item-title>
								</v-list-item>
								</v-list>
							</v-menu>
						</td>
					</tr>		
					<tr>						
						<td><a href="#">Выгрузка_1С_01012020.xlsx</a></td>
						<td>22.11.2020</td>
						<td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
						<td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
						<td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
						<td>17 575</td>
						<td>АНО «НИСИПП»</td>
						<td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
						<td><a href="#">Царь-монитор_2020</a></td>
						<td>Шевернев Ю.А.</td>
						<td>
						<v-menu
								bottom
								left
							>
								<template v-slot:activator="{ on, attrs }">
								<v-btn									
									icon
									v-bind="attrs"
									v-on="on"
								>
									<v-icon color="#77809A">mdi-dots-vertical</v-icon>
								</v-btn>
								</template>

								<v-list>
								<v-list-item>
									<v-list-item-title>Редактировать</v-list-item-title>									
								</v-list-item>
								<v-divider></v-divider>
								<v-list-item>
									<v-list-item-title>Удалить</v-list-item-title>
								</v-list-item>
								</v-list>
							</v-menu>
						</td>
					</tr>		
					<tr>						
						<td><a href="#">Выгрузка_1С_01012020.xlsx</a></td>
						<td>22.11.2020</td>
						<td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
						<td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
						<td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
						<td>17 575</td>
						<td>АНО «НИСИПП»</td>
						<td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
						<td><a href="#">Царь-монитор_2020</a></td>
						<td>Шевернев Ю.А.</td>
						<td>
						<v-menu
								bottom
								left
							>
								<template v-slot:activator="{ on, attrs }">
								<v-btn									
									icon
									v-bind="attrs"
									v-on="on"
								>
									<v-icon color="#77809A">mdi-dots-vertical</v-icon>
								</v-btn>
								</template>

								<v-list>
								<v-list-item>
									<v-list-item-title>Редактировать</v-list-item-title>									
								</v-list-item>
								<v-divider></v-divider>
								<v-list-item>
									<v-list-item-title>Удалить</v-list-item-title>
								</v-list-item>
								</v-list>
							</v-menu>
						</td>
					</tr>		
					<tr>						
						<td><a href="#">Выгрузка_1С_01012020.xlsx</a></td>
						<td>22.11.2020</td>
						<td>Списание с расчетного счета НИС00000103 от 26.11.2020 0:00:00 Оплата по  Счету 1512850459 от 05.03.2015 За право использования программы для ЭВМ «Контур - Экстерн» по тарифу «Оптимальный  Плюс» для ЮЛ на ОСН прод</td>
						<td>Контур СКБ ПФ ЗАО Счет 1512850459 от 26.11.2020 Списание с расчетного счета НИС00000103 от 26.03.2015 0:00:00</td>
						<td>Расчетный в ОАО «Промсвязьбанк» Оплата поставщику</td>
						<td>17 575</td>
						<td>АНО «НИСИПП»</td>
						<td><a href="#">Договор ДЭПР/91-09-14 от 16.09.2020</a></td>
						<td><a href="#">Царь-монитор_2020</a></td>
						<td>Шевернев Ю.А.</td>
						<td>
						<v-menu
								bottom
								left
							>
								<template v-slot:activator="{ on, attrs }">
								<v-btn									
									icon
									v-bind="attrs"
									v-on="on"
								>
									<v-icon color="#77809A">mdi-dots-vertical</v-icon>
								</v-btn>
								</template>

								<v-list>
								<v-list-item>
									<v-list-item-title>Редактировать</v-list-item-title>									
								</v-list-item>
								<v-divider></v-divider>
								<v-list-item>
									<v-list-item-title>Удалить</v-list-item-title>
								</v-list-item>
								</v-list>
							</v-menu>
						</td>
					</tr>		
				</tbody>
			</table>
			
		</section>


	</v-container>
</template>


<script>
import { entries1cRegisterConfig } from './register/RegisterConfigs';
import Register from './register/Register.vue';
import Add1сDialog from './dialogs/Add1сDialog';


export default {
	name: "Entries1cComponent",
	props: {},
	data() {
		return {
			regConfig: {
						tabs: [
								{
									title: 'Файлы',
									config: entries1cRegisterConfig
								},
								{
									title: 'Все проводки',
									config: entries1cRegisterConfig
								},
								{
									title: 'Нераспределенные',
									config: entries1cRegisterConfig
								},
							]

      }

		};
	},
	beforeMount() {
	},
	components: { Register, Add1сDialog }
}
</script>

<style lang="scss" scoped>
	table {
		width: 100%;
	}
	
</style>

