<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Редактирование состава полей</span>
          <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <v-container>



        <v-row>
         <v-col cols="6">
          <v-checkbox label="Организация"></v-checkbox>
         </v-col>
         <v-col cols="6">
          <v-checkbox label="Статья"></v-checkbox>
         </v-col>
        </v-row>

         <v-row>
         <v-col cols="6">
          <v-checkbox label="№ договора"></v-checkbox>
         </v-col>
         <v-col cols="6">
          <v-checkbox label="Год по бюджету"></v-checkbox>
         </v-col>
        </v-row>

         <v-row>
         <v-col cols="6">
          <v-checkbox label="Проект"></v-checkbox>
         </v-col>
         <v-col cols="6">
          <v-checkbox label="Контрагент"></v-checkbox>
         </v-col>
        </v-row>

         <v-row>
         <v-col cols="6">
          <v-checkbox label="Ответственный"></v-checkbox>
         </v-col>
         <v-col cols="6">
          <v-checkbox label="Номер счета"></v-checkbox>
         </v-col>
        </v-row>

         <v-row>
         <v-col cols="6">
          <v-checkbox label="Сумма (в проводки)"></v-checkbox>
         </v-col>
         <v-col cols="6">
          <v-checkbox label="Наименование вклада"></v-checkbox>
         </v-col>
        </v-row>

         <v-row>
         <v-col cols="6">
          <v-checkbox label="Доход / Расход"></v-checkbox>
         </v-col>
         <v-col cols="6">
          <v-checkbox label="Примечание"></v-checkbox>
         </v-col>
        </v-row>

         <v-row>
         <v-col cols="6">
          <v-checkbox label="Фонд"></v-checkbox>
         </v-col>

        </v-row>

      

      </v-container>

     
     
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          @click="$emit('close')"
          style="width: 100%"
      >
        Сохранить
      </v-btn>
    </v-card-actions>


  </v-card>
</template>

<script>
  export default {
    name: 'columnsDialog',

    data: () => ({
    }),
  }
</script>

<style lang="scss" scoped>

.v-dialog h4 {
  margin-bottom: 20px;
}
</style>
