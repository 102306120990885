<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Редактирование проводки</span>
          <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <v-container>
         <div class="">

          <v-row>
            
            <v-select
                label="организация"
                :items="['АНО «НИСИПП»', '2', '3', '4']"                
                outlined
                dense
                class="mb-2"
            ></v-select>
          
         </v-row>   

         <v-row>
            
            <v-select
                label="№ договора"
                :items="['Договор 01/25/62-20-2 от 20.09.2...', '2', '3', '4']"                
                outlined
                dense
                class="mb-2"
            ></v-select>
          
         </v-row>   

         <v-row>
            
            <v-select
                label="проект"
                :items="['1', '2', '3', '4']"                
                outlined
                dense
                class="mb-2"
            ></v-select>
          
         </v-row>  

          <v-row>
            
            <v-select
                label="ответственный"
                :items="['1', '2', '3', '4']"                
                outlined
                dense
                class="mb-2"
            ></v-select>
          
         </v-row> 

         <v-row>
            
            <v-text-field class="mb-2" outlined dense label="сумма, руб (в проводки)" value="1000000"></v-text-field>
          
         </v-row> 

        <v-row class="mb-2">
         
          <v-checkbox class="shrink mr-2" label="Доход"></v-checkbox>
          <v-checkbox class="shrink" label="Расход"></v-checkbox>
         
        </v-row>

         <v-row>
            
            <v-select
                label="фонд"
                :items="['1', '2', '3', '4']"                
                outlined
                dense
                class="mb-2"
            ></v-select>
          
         </v-row> 

          <v-row>
            
            <v-select
                label="статья"
                :items="['1', '2', '3', '4']"                
                outlined
                dense
                class="mb-2"
            ></v-select>
          
         </v-row> 

        

          <v-row>
            
            <v-select
                label="год по бюджету"
                :items="['2022', '2021', '3', '4']"                
                outlined
                dense
                class="mb-2"
            ></v-select>
          
         </v-row>  

          <v-row>
            
            <v-select
                label="№ счета"
                :items="['1', '2', '3', '4']"                
                outlined
                dense
                class="mb-2"
            ></v-select>
          
         </v-row>  

         <v-row>
            
            <v-select
                label="наименование вклада"
                :items="['1', '2', '3', '4']"                
                outlined
                dense
                class="mb-2"
            ></v-select>
          
         </v-row>  


<v-row>
            
            <v-select
                label="контрагент"
                :items="['1', '2', '3', '4']"                
                outlined
                dense
                class="mb-2"
            ></v-select>
          
         </v-row>  


       

         <v-row>
            
            <v-textarea outlined dense label="примечание"></v-textarea>
          
         </v-row> 

        </div>
      </v-container>

     
     
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          @click="$emit('close')"
          style="width: 100%"
      >
        Сохранить
      </v-btn>
    </v-card-actions>


  </v-card>
</template>

<script>
  export default {
    name: 'NewEntryDialog',

    data: () => ({
    }),
  }
</script>

<style lang="scss" scoped>
.add-link {
  margin-top: -10px;
  margin-bottom: 20px;
  display: block;
}
.v-dialog h4 {
  margin-bottom: 20px;
}
</style>
